import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.folderState || initialState;

export const foldersSelector = createSelector(selectDomain, (state) => state.folders);

export const folderStateSelector = createSelector(selectDomain, (state) => state);

export const foldersListDropdownSelector = createSelector(selectDomain, (state) => state.foldersList);

export const deletingFolderSelector = createSelector(selectDomain, (state) => state.deletingFolder);

export const updatingFolderSelector = createSelector(selectDomain, (state) => state.updatingFolder);


export const singleFolderSelector = createSelector(selectDomain, (state) => state.singleFolder);

