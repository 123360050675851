import axiosServices from 'utils/axios';
import { foldersParams } from 'utils/types/folders.types';

export const getAllFoldersService = async (params: foldersParams) => {
    try {
        const response = await axiosServices.get(`/folder/admin/list?limit=${params.limit}&skip=${params.skip}&search=${params.search}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const getAllFoldersDropdownService = async () => {
    try {
        const response = await axiosServices.get(`/folder/admin/folderlist`);
        return response;
    } catch (error) {
        return error;
    }
};

export const deleteFolderService = async (params: any) => {
    try {
        const response = await axiosServices.delete(`/folder/${params.folderId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const getFolderById = async (params: any) => {

    try {
        const response = await axiosServices.get(`/folder/${params.folderId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const createSingleFolder = async (values: any) => {
    try {
        const response = await axiosServices.post(`/folder`, values);
        return response;
    } catch (error) {
        return error;
    }
};

export const updateSingleFolder = async (id: string, values: any) => {
    try {
        const response = await axiosServices.put(`/folder/admin/${id}`, values);
        return response;
    } catch (error) {
        return error;
    }
};