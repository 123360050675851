import axiosServices from 'utils/axios';
import { loginParams } from 'utils/types/auth.types';

export const loginService = (reqParams: loginParams) => {
    try {
        const response = axiosServices.post('/auth/admin/login', reqParams);
        return response;
    } catch (error) {
        return error;
    }
};

export const logoutService = () => {
    try{
        const response = axiosServices.get('/auth/logout')
        return response;
    }catch(error){
        return error;
    }
}
