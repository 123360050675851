import { call, put } from 'redux-saga/effects';
import { createSliceSaga } from '../saga-helper';
import { AxiosResponse } from 'axios';
import { AccountReducerAction } from './slice';
import { loginParams } from 'utils/types/auth.types';
import { loginService, logoutService } from 'utils/services/auth.service';
import { setSession } from 'contexts/JWTContext';

const saga = createSliceSaga({
    name: 'account-saga',
    caseSagas: {
        *login(action: { payload: loginParams; type: string }) {
            try {
                const { email, password } = action.payload;
                const response: AxiosResponse = yield call(loginService, {
                    email,
                    password
                });

                if (response?.status === 200) {
                    const { tokenData, findUser: user } = response?.data?.data;
                    setSession(tokenData);
                    yield put(AccountReducerAction.setIsLoggedIn(true));
                    yield put(AccountReducerAction.setUserDetails(user));
                    yield put(AccountReducerAction.setError(null));
                }
            } catch (error) {
                console.log("error", error)
                yield put(AccountReducerAction.setError(error));
            }
        },
        *logout() {
            const response: AxiosResponse = yield call(logoutService);
            if (response?.status === 200) {
                setSession(null);
                yield put(AccountReducerAction.setIsLoggedIn(false));
                yield put(AccountReducerAction.setUserDetails(null));
            }
        }
    },
    sagaType: 'takeEvery'
});

export const { actions: AccountSagaActions, saga: AccountSaga, name: AccountSagaName } = saga;
