import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.accountState || initialState;

export const accountIsLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);

export const errorSelector = createSelector(
  selectDomain,
  (state) => state.error
);

export const userDetailsSelector = createSelector(
  selectDomain,
  (state) => state.userDetails
);

export const loggedSelector = createSelector(
  selectDomain,
  (state) => state.isLoggedIn
);