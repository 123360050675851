import { AxiosResponse } from 'axios';
import { createSliceSaga } from '../saga-helper';
import { call, put, select } from 'redux-saga/effects';
import { adsStateSelector } from './selector';
import { createAdService, deleteAdService, getAdById, getAllAdsDropdownList, getAllAdsService, updateSingleAd, uploadingFileService, uploadingSingleFileService } from 'utils/services/ads.service';
import { AdsReducerAction } from './slice';

const saga = createSliceSaga({
    name: 'ads-state',
    caseSagas: {

        *getAllAds() {
            try {
                // yield put(AdsReducerAction.deleteAdPending());
                const { limit, page, search, userId, pageId } = yield select(adsStateSelector);
                const skip = page - 1;
                const response: AxiosResponse = yield call(getAllAdsService, {
                    limit,
                    skip,
                    search,
                    userId,
                    pageId
                });
                if (response?.status === 200) {
                    // yield put(AdsReducerAction.deleteAdSuccess());
                    const updatedAdsList = {
                        data: [...response?.data?.data],
                        total: response?.data?.total
                    };
                    yield put(AdsReducerAction.setAdsList(updatedAdsList));
                } else {
                    console.log("no response")
                    // yield put(AdsReducerAction.deleteAdFailure());
                }
            } catch (error) {
                console.log("error", error)
                yield put(AdsReducerAction.deleteAdFailure());
            }
        },

        *deleteAd(action: { payload: string }) {
            try {
                yield put(AdsReducerAction.deleteAdPending());
                const adId = action.payload;
                yield call(deleteAdService, { adId });
                yield put(AdsSagaActions.getAllAds());
            } catch (error) {
                console.error('Error deleting Ad:', error);
                yield put(AdsReducerAction.deleteAdFailure());
            } finally {
                yield put(AdsReducerAction.deleteAdSuccess());
            }
        },

        *singleAd(action: { payload: string }) {
            try {
                yield put(AdsReducerAction.deleteAdPending());
                const adId = action.payload;
                const response: AxiosResponse = yield call(getAdById, { adId });

                if (response?.status === 200) {
                    yield put(AdsReducerAction.deleteAdSuccess());
                    const singleAdData = response?.data?.data
                    yield put(AdsReducerAction.setSingleAd(singleAdData));
                } {
                    yield put(AdsReducerAction.deleteAdFailure());
                }
            } catch (error) {
                console.error('Error deleting Ad:', error);
                yield put(AdsReducerAction.deleteAdFailure());

            }
        },

        *uploadFile(action: { payload: { values: any } }) {
            try {
                yield put(AdsReducerAction.uploadingFilePending());

                const { values } = action.payload;
                console.log("filessss", values)
                const response: AxiosResponse = yield call(uploadingFileService, values);

                if (response?.status === 200) {
                    yield put(AdsReducerAction.uploadingFileSuccess(response.data));
                    yield put(AdsReducerAction.uploadedFileUrls(response?.data?.data));
                } else {
                    yield put(AdsReducerAction.uploadingFileFailure('Failed to Create Ad.'));
                }
            } catch (error) {
                console.error("Error Updating Ad:", error);
                yield put(AdsReducerAction.uploadingFileFailure(error.message || 'Error Creating Ad.'));
            }
        },

        *uploadSingleFile(action: { payload: { values: any } }) {
            try {
                yield put(AdsReducerAction.uploadingFilePending());
                const { values } = action.payload;

                const response: AxiosResponse = yield call(uploadingSingleFileService, values);

                if (response?.status === 200) {
                    console.log("response", response)
                    yield put(AdsReducerAction.uploadingFileSuccess(response.data));
                    yield put(AdsReducerAction.uploadedSingleFileUrl(response?.data?.data));
                } else {
                    yield put(AdsReducerAction.uploadingFileFailure('Failed to Upload logo.'));
                }
            } catch (error) {
                console.error("Error Uploading File", error);
                yield put(AdsReducerAction.uploadingFileFailure(error.message || 'Error Uploading File.'));
            }
        },

        *createAd(action: { payload: { values: any } }) {
            try {

                yield put(AdsReducerAction.updateAdPending());

                const { values } = action.payload;
                const response: AxiosResponse = yield call(createAdService, values);

                if (response?.status === 200) {
                    yield put(AdsReducerAction.updateAdSuccess(response.data));
                } else {
                    yield put(AdsReducerAction.updateAdFailure('Failed to Create Ad.'));
                }
            } catch (error) {
                console.error("Error Updating Ad:", error);

                yield put(AdsReducerAction.updateAdFailure(error.message || 'Error Creating Ad.'));
            }
        },

        *updateAd(action: { payload: { id: string; values: any } }) {
            try {
                yield put(AdsReducerAction.updateAdPending());
                const { id, values } = action.payload;
                const response: AxiosResponse = yield call(updateSingleAd, id, values);
                if (response?.status === 200) {

                    yield put(AdsReducerAction.updateAdSuccess(response.data));
                    yield put(AdsSagaActions.singleUser(id));
                } else {

                    yield put(AdsReducerAction.updateAdFailure('Failed to update Ad.'));
                }
            } catch (error) {
                console.error("Error updating user:", error);

                yield put(AdsReducerAction.updateAdFailure(error.message || 'Error updating Ad.'));
            }
        },
        *getAllAdsDropdown() {
            try {
                yield put(AdsReducerAction.deleteAdPending());
                const response: AxiosResponse = yield call(getAllAdsDropdownList);
                if (response?.status === 200) {
                    const dropDownList = {
                        data: [...response?.data?.data],
                    };
                    console.log("response?.data?.data", response?.data?.data)
                    yield put(AdsReducerAction.setAdsDropdownList(dropDownList));
                } else {
                    console.log("error")
                }
            } catch (error) {
                console.log("error", error)
                yield put(AdsReducerAction.deleteAdFailure());
            }
        },

    },
    sagaType: 'takeEvery'
});

export const { actions: AdsSagaActions, saga: AdsSaga, name: AdsSagaName } = saga;
