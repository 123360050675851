import axiosServices from 'utils/axios';
import { brandParams } from 'utils/types/brand.types';


export const getAllBrandsService = async (params: brandParams) => {
    try {
        const response = await axiosServices.get(`/brand?limit=${params.limit}&skip=${params.skip}&search=${params.search}`);
        return response;
    } catch (error) {
        return error;
    }
};


export const deleteBrandService = async (params: any) => {
    try {
        const response = await axiosServices.delete(`/brand/${params.brandId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const getBrandById = async (params: any) => {

    try {
        const response = await axiosServices.get(`/brand/${params.brandId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const createSingleBrand = async (values: any) => {
    try {
        const response = await axiosServices.post(`/brand`, values);
        return response;
    } catch (error) {
        return error;
    }
};

export const updateSingleBrand = async (id: string, values: any) => {
    try {
        const response = await axiosServices.put(`/brand/${id}`, values);
        return response;
    } catch (error) {
        return error;
    }
};