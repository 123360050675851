import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.userState || initialState;

export const usersSelector = createSelector(selectDomain, (state) => state.users);

export const userStateSelector = createSelector(selectDomain, (state) => state);

export const deletingUserSelector = createSelector(selectDomain, (state) => state.deletingUser);

export const updatingUserSelector = createSelector(selectDomain, (state) => state.updatingUser);


export const singleUserSelector = createSelector(selectDomain, (state) => state.singleUser);

