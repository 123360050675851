
import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Notistack from 'ui-component/third-party/Notistack';
import ThemeCustomization from 'themes';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { MetaDataReducer, MetaDataReducerName } from 'store/metadata/slice';
import { MetaDataSaga, MetaDataSagaName } from 'store/metadata/sagas';
import { AccountReducer, AccountReducerName } from 'store/account/slice';
import { AccountSaga, AccountSagaName } from 'store/account/sagas';
import { UserReducer, UserReducerName } from 'store/user/slice';
import { UserSaga, UserSagaName } from 'store/user/sagas';
import { PlanReducer, PlanReducerName } from 'store/plan/slice';
import { PlanSaga, PlanSagaName } from 'store/plan/sagas';
import { AdsReducer, AdsReducerName } from 'store/ads/slice';
import { AdsSaga, AdsSagaName } from 'store/ads/sagas';
import { BrandReducer, BrandReducerName } from 'store/brand/slice';
import { BrandSaga, BrandSagaName } from 'store/brand/sagas';
import { TeamSaga, TeamSagaName } from 'store/teams/sagas';
import { TeamReducer, TeamReducerName } from 'store/teams/slice';
import { TermsReducer, TermsReducerName } from 'store/termsAndCondition/slice';
import { TermsSaga, TermsSagaName } from 'store/termsAndCondition/sagas';
import { FolderReducer, FolderReducerName } from 'store/folders/slice';
import { FolderSaga, FolderSagaName } from 'store/folders/sagas';
import { BoardReducer, BoardReducerName } from 'store/boards/slice';
import { BoardSaga, BoardSagaName } from 'store/boards/sagas';

const App = () => {

    useInjectReducer({
        reducer: AccountReducer,
        key: AccountReducerName
    });

    useInjectReducer({
        reducer: MetaDataReducer,
        key: MetaDataReducerName
    });

    useInjectReducer({
        reducer: UserReducer,
        key: UserReducerName
    });

    useInjectReducer({
        reducer: AdsReducer,
        key: AdsReducerName
    });

    useInjectReducer({
        reducer: PlanReducer,
        key: PlanReducerName
    });

    useInjectReducer({
        reducer: BrandReducer,
        key: BrandReducerName
    });

    useInjectReducer({
        reducer: TeamReducer,
        key: TeamReducerName
    });

    useInjectReducer({
        reducer: FolderReducer,
        key: FolderReducerName
    });

    useInjectReducer({
        reducer: BoardReducer,
        key: BoardReducerName
    });

    useInjectReducer({
        reducer: TermsReducer,
        key: TermsReducerName
    });

    useInjectSaga({
        saga: MetaDataSaga,
        key: MetaDataSagaName
    });

    useInjectSaga({
        saga: AccountSaga,
        key: AccountSagaName
    });

    useInjectSaga({
        saga: UserSaga,
        key: UserSagaName
    });

    useInjectSaga({
        saga: AdsSaga,
        key: AdsSagaName
    });

    useInjectSaga({
        saga: PlanSaga,
        key: PlanSagaName
    });

    useInjectSaga({
        saga: BrandSaga,
        key: BrandSagaName
    });

    useInjectSaga({
        saga: TeamSaga,
        key: TeamSagaName
    });

    useInjectSaga({
        saga: FolderSaga,
        key: FolderSagaName
    });

    useInjectSaga({
        saga: BoardSaga,
        key: BoardSagaName
    });

    useInjectSaga({
        saga: TermsSaga,
        key: TermsSagaName
    });

    return (
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <>
                                <Notistack>
                                    <RouterProvider router={router} />
                                    <Snackbar />
                                </Notistack>
                            </>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
