import { createSlice } from '@reduxjs/toolkit';
import { BoardsInitialStateType } from './types';




export const initialState: BoardsInitialStateType = {
    error: null,
    boards: {},
    limit: 10,
    page: 1,
    search: "",
    singleBoard: {},
    deletingBoard: false,
    updatingBoard: false,
};

const board = createSlice({
    name: 'boardState',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setBoardList(state, action) {
            state.boards = action.payload;
        },

        setCurrentPage(state, action) {
            state.page = action.payload;
        },

        setLimit(state, action) {
            state.limit = action.payload;
        },

        setSearch(state, action) {
            state.search = action.payload;
        },

        setSingleBoard(state, action) {
            state.singleBoard = action.payload
        },

        deleteBoardPending(state) {
            state.deletingBoard = true;
        },
        deleteBoardSuccess(state) {
            state.deletingBoard = false;
        },
        deleteBoardFailure(state) {
            state.deletingBoard = false;
        },
        updateBoardPending(state) {
            state.updatingBoard = true;
            state.error = null;
        },
        updateBoardSuccess(state, action) {
            state.updatingBoard = false;
        },
        updateBoardFailure(state, action) {
            state.error = action.payload;
            state.updatingBoard = false;
        },

    }
});

export const { actions: BoardReducerAction, name: BoardReducerName, reducer: BoardReducer } = board;
