import axiosServices from 'utils/axios';
import { teamsParams } from 'utils/types/teams.types';

export const getAllTeamsService = async (params: teamsParams) => {
    try {
        const response = await axiosServices.post(`/team/list?limit=${params.limit}&skip=${params.skip}&search=${params.search}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const getAllUsersService = async () => {
    try {
        const response = await axiosServices.get(`/users/list`);
        return response;
    } catch (error) {
        return error;
    }
};

export const deleteTeamService = async (params: any) => {
    try {
        const response = await axiosServices.delete(`/team/removeTeam/${params.teamId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const getTeamById = async (params: any) => {
    console.log("params", params)
    try {
        const response = await axiosServices.get(`/team/${params.teamId}`);
        return response;
    } catch (error) {
        return error;
    }
};

export const createSingleTeam = async (values: any) => {
    try {
        const response = await axiosServices.post(`/team`, values);
        return response;
    } catch (error) {
        return error;
    }
};

export const updateSingleTeam = async (id: string, values: any) => {
    try {
        const response = await axiosServices.put(`/team/updateTeam/${id}`, values);
        return response;
    } catch (error) {
        return error;
    }
};