import { AxiosResponse } from 'axios';
import { createSliceSaga } from '../saga-helper';

import { call, put, select } from 'redux-saga/effects';
import { folderStateSelector } from './selector';
import { createSingleFolder, deleteFolderService, getAllFoldersDropdownService, getAllFoldersService, getFolderById, updateSingleFolder } from 'utils/services/folders.service';
import { FolderReducerAction } from './slice';

const saga = createSliceSaga({
    name: 'folder-state',
    caseSagas: {
        *getAllFolder() {
            try {
                yield put(FolderReducerAction.updateFolderPending());
                const { limit, page, search } = yield select(folderStateSelector);
                const skip = page - 1;
                const response: AxiosResponse = yield call(getAllFoldersService, {
                    limit,
                    skip,
                    search
                });
                if (response?.status === 200) {
                    yield put(FolderReducerAction.updateFolderSuccess(response.data));
                    const updatedFolderList = {
                        data: [...response?.data?.data?.folders],
                        total: response?.data?.data?.total
                    };
                    yield put(FolderReducerAction.setFoldersList(updatedFolderList));
                } else {
                    yield put(FolderReducerAction.updateFolderFailure('Failed to Get Folder.'));
                }
            } catch (error) {
                console.log("error", error)
                yield put(FolderReducerAction.updateFolderFailure('Failed to Get Folder.'));
            }
        },
        *deleteFolder(action: { payload: string }) {

            try {
                yield put(FolderReducerAction.deleteFolderPending());
                const folderId = action.payload;
                yield call(deleteFolderService, { folderId });
                yield put(FolderSagaActions.getAllFolder());
            } catch (error) {
                console.error('Error Deleting Folder:', error);
                yield put(FolderReducerAction.deleteFolderFailure());
            } finally {
                yield put(FolderReducerAction.deleteFolderSuccess());
            }

        },
        *singleFolder(action: { payload: string }) {
            try {
                yield put(FolderReducerAction.updateFolderPending());
                const folderId = action.payload;

                if (folderId) {
                    const response: AxiosResponse = yield call(getFolderById, { folderId });
                    if (response?.status === 200) {
                        yield put(FolderReducerAction.updateFolderSuccess(response.data));
                        const singleFolderData = {
                            data: response?.data?.data || {},
                        };
                        yield put(FolderReducerAction.setSingleFolder(singleFolderData));
                    }
                } else {
                    yield put(FolderReducerAction.setSingleFolder({}));
                    yield put(FolderReducerAction.updateFolderFailure('Failed to Get Folder.'));
                }

            } catch (error) {
                console.error('Error Single Folder:', error);
                yield put(FolderReducerAction.updateFolderFailure('Failed to Get Folder.'));

            }
        },
        *createFolder(action: { payload: { values: any } }) {
            try {
                yield put(FolderReducerAction.updateFolderPending());

                const { values } = action.payload;
                const response: AxiosResponse = yield call(createSingleFolder, values);

                if (response?.status === 200) {
                    yield put(FolderReducerAction.updateFolderSuccess(response.data));
                } else {
                    yield put(FolderReducerAction.updateFolderFailure('Failed to Create Folder.'));
                }
            } catch (error) {
                console.error("Error Updating Folder:", error);
                yield put(FolderReducerAction.updateFolderFailure(error.message || 'Error Creating Folder.'));
            }
        },
        *updateFolder(action: { payload: { id: string; values: any } }) {
            try {
                yield put(FolderReducerAction.updateFolderPending());

                const { id, values } = action.payload;
                const response: AxiosResponse = yield call(updateSingleFolder, id, values);

                if (response?.status === 200) {
                    yield put(FolderReducerAction.updateFolderSuccess(response.data));
                    yield put(FolderSagaActions.singleFolder(id));
                } else {
                    yield put(FolderReducerAction.updateFolderFailure('Failed to update Folder.'));
                }
            } catch (error) {
                console.error("Error Updating Folder:", error);
                yield put(FolderReducerAction.updateFolderFailure(error.message || 'Error updating Folder.'));
            }
        },
        *getAllFoldersDropdown() {
            try {
                const response: AxiosResponse = yield call(getAllFoldersDropdownService);
                if (response?.status === 200) {
                    yield put(FolderReducerAction.updateFolderSuccess(response.data));
                    yield put(FolderReducerAction.setFoldersListDropdown(response?.data?.data));
                } else {
                    yield put(FolderReducerAction.updateFolderFailure('Failed to Get Folder.'));
                }
            } catch (error) {
                console.log("error", error)
                yield put(FolderReducerAction.updateFolderFailure('Failed to Get Folder.'));
            }
        },

    },
    sagaType: 'takeEvery'
});

export const { actions: FolderSagaActions, saga: FolderSaga, name: FolderSagaName } = saga;
