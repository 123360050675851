import axiosServices from 'utils/axios';
import { termsAndCondition } from 'utils/types/termsAndCondition';


export const getTermsAndCondition = async (params: termsAndCondition) => {
    console.log("params", params)
    try {
        const response = await axiosServices.get(`/document?documentType=${params.type}`);
        console.log("response", response)
        return response;
    } catch (error) {
        return error;
    }
};

export const updateTerms = async (values: any) => {
    try {
        const response = await axiosServices.post(`/document`, values);
        return response;
    } catch (error) {
        return error;
    }
};