import { AxiosResponse } from 'axios';
import { createSliceSaga } from '../saga-helper';
import { call, put, select } from 'redux-saga/effects';

import { boardStateSelector } from './selector';
import { createSingleBoard, deleteBoardsService, getAllBoardsService, getBoardById, updateSingleBoard } from 'utils/services/boards.service';
import { BoardReducerAction } from './slice';

const saga = createSliceSaga({
    name: 'board-state',
    caseSagas: {
        *getAllBoards() {
            try {
                yield put(BoardReducerAction.updateBoardPending());
                const { limit, page, search } = yield select(boardStateSelector);
                const skip = page - 1;
                const response: AxiosResponse = yield call(getAllBoardsService, {
                    limit,
                    skip,
                    search
                });
                console.log("response", response)
                if (response?.status === 200) {
                    yield put(BoardReducerAction.updateBoardSuccess(response.data));

                    const updatedBoardList = {
                        data: [...response?.data?.data],
                        total: response?.data?.total
                    };
                    yield put(BoardReducerAction.setBoardList(updatedBoardList));
                } else {
                    yield put(BoardReducerAction.updateBoardFailure('Failed to Get Board.'));
                }
            } catch (error) {
                console.log("error", error)
                yield put(BoardReducerAction.updateBoardFailure('Failed to Get Board.'));
            }
        },
        *deleteBoard(action: { payload: string }) {

            try {
                yield put(BoardReducerAction.deleteBoardPending());
                const boardId = action.payload;
                yield call(deleteBoardsService, { boardId });
                yield put(BoardSagaActions.getAllBoards());
            } catch (error) {
                console.error('Error Deleting Board:', error);
                yield put(BoardReducerAction.deleteBoardFailure());
            } finally {
                yield put(BoardReducerAction.deleteBoardSuccess());
            }

        },
        *singleBoard(action: { payload: string }) {
            try {
                yield put(BoardReducerAction.updateBoardPending());
                const boardId = action.payload;

                if (boardId) {
                    const response: AxiosResponse = yield call(getBoardById, { boardId });
                    if (response?.status === 200) {
                        yield put(BoardReducerAction.updateBoardSuccess(response.data));
                        const singleBoardData = {
                            data: response?.data?.data || {},
                        };
                        yield put(BoardReducerAction.setSingleBoard(singleBoardData));
                    }
                } else {
                    yield put(BoardReducerAction.setSingleBoard({}));
                    yield put(BoardReducerAction.updateBoardFailure('Failed to Get Board.'));
                }

            } catch (error) {
                console.error('Error Single Board:', error);
                yield put(BoardReducerAction.updateBoardFailure('Failed to Get Board.'));

            }
        },
        *createBoard(action: { payload: { values: any } }) {
            try {
                yield put(BoardReducerAction.updateBoardPending());

                const { values } = action.payload;
                const response: AxiosResponse = yield call(createSingleBoard, values);

                if (response?.status === 200) {
                    yield put(BoardReducerAction.updateBoardSuccess(response.data));
                } else {
                    yield put(BoardReducerAction.updateBoardFailure('Failed to Create Board.'));
                }
            } catch (error) {
                console.error("Error Updating Board:", error);
                yield put(BoardReducerAction.updateBoardFailure(error.message || 'Error Creating Board.'));
            }
        },
        *updateBoard(action: { payload: { id: string; values: any } }) {
            try {
                yield put(BoardReducerAction.updateBoardPending());

                const { id, values } = action.payload;
                const response: AxiosResponse = yield call(updateSingleBoard, id, values);

                if (response?.status === 200) {
                    yield put(BoardReducerAction.updateBoardSuccess(response.data));
                    yield put(BoardSagaActions.singleBoard(id));
                } else {
                    yield put(BoardReducerAction.updateBoardFailure('Failed to update Board.'));
                }
            } catch (error) {
                console.error("Error Updating Board:", error);
                yield put(BoardReducerAction.updateBoardFailure(error.message || 'Error updating Board.'));
            }
        },

    },
    sagaType: 'takeEvery'
});

export const { actions: BoardSagaActions, saga: BoardSaga, name: BoardSagaName } = saga;
