import { createSlice } from '@reduxjs/toolkit';
import { BrandInitialStateType } from './types';

export const initialState: BrandInitialStateType = {
    error: null,
    brands: {},
    limit: 10,
    page: 1,
    search: "",
    singleBrand: {},
    deletingBrand: false,
    updatingBrand: false,
};

const user = createSlice({
    name: 'brandState',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setBrandsList(state, action) {
            state.brands = action.payload;
        },

        setCurrentPage(state, action) {
            state.page = action.payload;
        },

        setLimit(state, action) {
            state.limit = action.payload;
        },

        setSearch(state, action) {
            state.search = action.payload;
        },

        setSingleBrand(state, action) {
            state.singleBrand = action.payload
        },

        deleteBrandPending(state) {
            state.deletingBrand = true;
        },
        deleteBrandSuccess(state) {
            state.deletingBrand = false;
        },
        deleteBrandFailure(state) {
            state.deletingBrand = false;
        },
        updateBrandPending(state) {
            state.updatingBrand = true;
            state.error = null;
        },
        updateBrandSuccess(state, action) {
            state.updatingBrand = false;
        },
        updateBrandFailure(state, action) {
            state.error = action.payload;
            state.updatingBrand = false;
        },

    }
});

export const { actions: BrandReducerAction, name: BrandReducerName, reducer: BrandReducer } = user;
