import { useNavigate } from 'react-router-dom';

// project imports
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { loggedSelector } from 'store/account/selector';
import { useSelector } from 'react-redux';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const isLoggedIn = useSelector(loggedSelector);
    console.log('isLoggedIn: ', isLoggedIn);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return children;
};

export default AuthGuard;
