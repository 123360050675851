import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.metaDataState || initialState;

export const drawerSelector = createSelector(selectDomain, (state) => state.drawerOpen);

export const menuSelector = createSelector(selectDomain, (state) => state.menu);

export const selectIdSelector = createSelector(selectDomain, (state) => state.selectedID);

export const activeIdSelector = createSelector(selectDomain, (state) => state.activeID);

export const snackBarSliceSelectors = createSelector(selectDomain, (state) => state);
