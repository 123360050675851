import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.brandState || initialState;

export const brandsSelector = createSelector(selectDomain, (state) => state.brands);

export const brandStateSelector = createSelector(selectDomain, (state) => state);

export const deletingBrandSelector = createSelector(selectDomain, (state) => state.deletingBrand);

export const updatingBrandSelector = createSelector(selectDomain, (state) => state.updatingBrand);


export const singleBrandSelector = createSelector(selectDomain, (state) => state.singleBrand);

