import { createSlice } from '@reduxjs/toolkit';
import { AdsInitialStateType } from './types';


export const initialState: AdsInitialStateType = {
    error: null,
    ads: {},
    adsList: {},
    limit: 10,
    search: "",
    userId: "",
    pageId: "",
    sort: "",
    page: 1,
    singleAd: {},
    deletingAd: false,
    updatingAd: false,
    uploadingFile: false,
    fileUrls: {},
    singleFileUrl: "",
};

const ads = createSlice({
    name: 'adsState',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },

        setAdsList(state, action) {
            state.ads = action.payload;
        },

        setAdsDropdownList(state, action) {
            state.adsList = action.payload;
        },

        setCurrentPage(state, action) {
            state.page = action.payload;
        },

        setLimit(state, action) {
            state.limit = action.payload;
        },

        setSearch(state, action) {
            state.search = action.payload;
        },

        setSelectedUser(state, action) {
            state.userId = action.payload;
        },
        setSelectedBrand(state, action) {
            state.pageId = action.payload;
        },

        setSort(state, action) {
            state.sort = action.payload;
        },

        setSingleAd(state, action) {
            state.singleAd = action.payload
        },

        deleteAdPending(state) {
            state.deletingAd = true;
        },
        deleteAdSuccess(state) {
            state.deletingAd = false;
        },
        deleteAdFailure(state) {
            state.deletingAd = false;
        },

        updateAdPending(state) {
            state.updatingAd = true;
            state.error = null;
        },
        updateAdSuccess(state, action) {
            state.singleAd = action.payload;
            state.updatingAd = false;
        },
        updateAdFailure(state, action) {
            state.error = action.payload;
            state.updatingAd = false;
        },

        uploadingFilePending(state) {
            state.uploadingFile = true;
            state.error = null;
        },
        uploadingFileSuccess(state, action) {
            state.uploadingFile = false;
        },
        uploadingFileFailure(state, action) {
            state.error = action.payload;
            state.uploadingFile = false;
        },

        uploadedFileUrls(state, action) {
            state.fileUrls = action.payload;
            state.uploadingFile = false;
        },

        uploadedSingleFileUrl(state, action) {
            state.singleFileUrl = action.payload;
            state.uploadingFile = false;
        },

    }
});

export const { actions: AdsReducerAction, name: AdsReducerName, reducer: AdsReducer } = ads;
