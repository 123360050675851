import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

const selectDomain = (state: any) => state.teamState || initialState;

export const teamsSelector = createSelector(selectDomain, (state) => state.teams);

export const usersListSelector = createSelector(selectDomain, (state) => state.usersList);

export const teamStateSelector = createSelector(selectDomain, (state) => state);

export const deletingTeamSelector = createSelector(selectDomain, (state) => state.deletingTeam);

export const updatingTeamSelector = createSelector(selectDomain, (state) => state.updatingTeam);


export const singleTeamSelector = createSelector(selectDomain, (state) => state.singleTeam);

